const nameInput = document.getElementById("nameInput");
const emailInput = document.getElementById("emailInput");
const commentInput = document.getElementById("commentInput");

document.getElementById("contactForm").addEventListener("change", () => {
  const name = nameInput.value;
  const email = emailInput.value;
  const comment = commentInput.value;
  const warning = "";
  let str = "mailto:eat@hoodburger.com.au?subject=Hoodburger Contact Form&body=";
  if (comment.length > 0) {
    str += `%0D%0A${comment}.`;
  }
  str += `%0D%0ACheers,%0D%0A${name}%0D%0A${email}`;
  document.getElementById("sendContact").href = str;
  document.getElementById("sendContact").classList.add("btn-active");
});

document.getElementById("jobForm").addEventListener("change", () => {
  const name = nameInput.value;
  const email = emailInput.value;
  const comment = commentInput.value;
  const warning = "";
  let str = "mailto:hoodburger.jobs@gmail.com?subject=Hoodburger Job&body=";
  if (comment.length > 0) {
    str += `%0D%0A${comment}.`;
  }
  str += `%0D%0ACheers,%0D%0A${name}%0D%0A${email}`;
  document.getElementById("sendJobs").href = str;
  document.getElementById("sendJobs").classList.add("btn-active");
});

const aboutSectionButton = document.querySelector(".js-hoodburger-about");
const aboutSectionClose = document.querySelector(".about-section--close");
const aboutSectionPage = document.querySelector(".js-about-page");
let newLeft = "";

function openAbout() {
  const sLeft = window.pageXOffset;
  aboutSectionPage.classList.add("is-visible");
  document.body.classList.add("scroll-lock");
  document.documentElement.classList.add("scroll-lock");
  window.history.replaceState(
    "about",
    "About Hoodburger",
    "hoodburger-the-story"
  );
  newLeft = sLeft;
}

function closeAbout() {
  aboutSectionPage.classList.remove("is-visible");
  document.body.classList.remove("scroll-lock");
  document.documentElement.classList.remove("scroll-lock");
  window.history.replaceState("homepage", "Hoodburger", "/");
  window.scrollTo(newLeft, 0);
}

aboutSectionButton.addEventListener("click", () => {
  openAbout();
});

aboutSectionClose.addEventListener("click", () => {
  closeAbout();
});

const jobsSectionButton = document.querySelector(".js-hoodburger-jobs");
const jobsSectionClose = document.querySelector(".js-jobs-section--close");
const jobsSectionPage = document.querySelector(".js-jobs-page");

function openJobs() {
  const sLeft = window.pageXOffset;
  jobsSectionPage.classList.add("is-visible");
  document.body.classList.add("scroll-lock");
  document.documentElement.classList.add("scroll-lock");
  window.history.replaceState("jobs", "Hoodburger Jobs", "hoodburger-jobs");
  newLeft = sLeft;
}

function closeJobs() {
  jobsSectionPage.classList.remove("is-visible");
  document.body.classList.remove("scroll-lock");
  document.documentElement.classList.remove("scroll-lock");
  window.history.replaceState("homepage", "Hoodburger", "/");
  window.scrollTo(newLeft, 0);
}

jobsSectionButton.addEventListener("click", () => {
  openJobs();
});

jobsSectionClose.addEventListener("click", () => {
  closeJobs();
});

const socialsSectionButton = document.querySelector(".js-hoodburger-socials");
const socialsSectionClose = document.querySelector(
  ".js-socials-section--close"
);
const socialsSectionPage = document.querySelector(".js-socials-page");

function openSocials() {
  const sLeft = window.pageXOffset;
  socialsSectionPage.classList.add("is-visible");
  document.body.classList.add("scroll-lock");
  document.documentElement.classList.add("scroll-lock");
  window.history.replaceState(
    "socials",
    "Hoodburger Socials",
    "hoodburger-socials"
  );
  newLeft = sLeft;
}

function closeSocials() {
  socialsSectionPage.classList.remove("is-visible");
  document.body.classList.remove("scroll-lock");
  document.documentElement.classList.remove("scroll-lock");
  window.history.replaceState("homepage", "Hoodburger", "/");
  window.scrollTo(newLeft, 0);
}

socialsSectionButton.addEventListener("click", () => {
  openSocials();
});

socialsSectionClose.addEventListener("click", () => {
  closeSocials();
});

const contactSectionButton = document.querySelector(".js-hoodburger-contact");
const contactSectionClose = document.querySelector(
  ".js-contact-section--close"
);
const contactSectionPage = document.querySelector(".js-contact-page");

function openContact() {
  const sLeft = window.pageXOffset;
  contactSectionPage.classList.add("is-visible");
  document.body.classList.add("scroll-lock");
  document.documentElement.classList.add("scroll-lock");
  window.history.pushState(
    "contact",
    "Hoodburger Contact",
    "hoodburger-the-contact"
  );
  newLeft = sLeft;
}

function closeContact() {
  contactSectionPage.classList.remove("is-visible");
  document.body.classList.remove("scroll-lock");
  document.documentElement.classList.remove("scroll-lock");
  window.history.replaceState("homepage", "Hoodburger", "/");
  window.scrollTo(newLeft, 0);
}

contactSectionButton.addEventListener("click", () => {
  openContact();
});

contactSectionClose.addEventListener("click", () => {
  closeContact();
});

const locationSectionButton = document.querySelector(".js-hoodburger-location");
const locationSectionClose = document.querySelector(".location-section--close");
const locationSectionPage = document.querySelector(".js-location-page");

function openLocation() {
  const sLeft = window.pageXOffset;
  locationSectionPage.classList.add("is-visible");
  document.body.classList.add("scroll-lock");
  document.documentElement.classList.add("scroll-lock");
  window.history.replaceState(
    "location",
    "Location Hoodburger",
    "hoodburger-the-locations"
  );
  newLeft = sLeft;
}

function closeLocation() {
  locationSectionPage.classList.remove("is-visible");
  document.body.classList.remove("scroll-lock");
  document.documentElement.classList.remove("scroll-lock");
  window.history.replaceState("homepage", "Hoodburger", "/");
  window.scrollTo(newLeft, 0);
}

locationSectionButton.addEventListener("click", () => {
  openLocation();
});

locationSectionClose.addEventListener("click", () => {
  closeLocation();
});

function checkURLchange() {
  if (window.location.href.indexOf("#hoodburger-the-story") > -1) {
    openAbout();
  }
  if (window.location.href.indexOf("#hoodburger-the-menu") > -1) {
    openContact();
  }
  if (window.location.href.indexOf("#hoodburger-the-locations") > -1) {
    openLocation();
  }
}

checkURLchange();

window.addEventListener(
  "hashchange",
  e => {
    console.log("close about");
    checkURLchange(e);
  },
  false
);

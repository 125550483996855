// Material Design Components
import { MDCTopAppBar } from "@material/top-app-bar/index";
import { MDCSelect, MDCSelectFoundation } from "@material/select";
import { MDCTextField } from "@material/textfield";

/* Avoid error throwing on full page layouts */
function initCatalogLayout(el) {
  if (!el) return;

  // const topAppBar = new MDCTopAppBar(el);
  const select = new MDCSelect(document.querySelector(".custom-select"));
  // const textField = new MDCTextField(document.querySelector(".mdc-text-field"));
}

initCatalogLayout(document.querySelector(".custom-select"));
